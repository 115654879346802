import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild, 
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate, CanActivateChild  {
  constructor(public authService: AuthService, public router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ){
    if (this.authService.isLoggedIn == true) {
      this.router.navigate(['login']);
    }
    return true;
  }



  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
    if (this.authService.isLoggedIn == true) {
      this.router.navigate(['/login']);
    }
    return true;
  }
}