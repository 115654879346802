import { Component, OnInit, Input } from '@angular/core';
import { NbSpinnerService } from '@nebular/theme';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { environment } from '../../environments/firebaseConfiguration';
import { setDoc, doc, getDocs, collection } from "firebase/firestore";
import { FirebaseService } from '../firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Portal } from '@angular/cdk/portal';
// import { HeaderComponent } from '../@theme/components';

@Component({
  selector: 'app-single-preview',
  templateUrl: './single-preview.component.html',
  styleUrls: ['./single-preview.component.css']
})
export class SinglePreviewComponent implements OnInit{
  showComponent = false;
  isDisabled =true;
  nextBtnEnable= true;
  siteNames = [];
  cameraNames = []

  currentItem = '';
  transIDExist: any;
  app = initializeApp(environment.firebaseConfig);
  // Initialize Cloud Firestore and get a reference to the service
  db = getFirestore(this.app);
  userList =[];
  selectedSiteId: string;
  selectedCamera: string;
  site_details: any;
  userId = '';
  userName = '';
  transId = '';
  tokenData = {};

  constructor (private fireService: FirebaseService, private spinnerService: NbSpinnerService, private route: ActivatedRoute, private router: Router){
    this.spinnerService.load();

  }

  
  ngOnInit() {
    let token = this.route.snapshot.queryParamMap.get('token');
    setTimeout(()=>{
      this.fireService.getToken(token).subscribe((val)=>{
        this.tokenData = val;
        this.userId = this.tokenData['decoded_token'].user_id;
        this.userName = this.tokenData['decoded_token'].username;
        this.transId = this.tokenData['decoded_token'].transaction_id;
        this.currentItem = this.transId;
      });  
   },1000)
    // this.currentItem = transId;
    const siteList = this.fireService.getSite();
    siteList.then((val)=>{
      val.forEach((result)=>{
        this.siteNames.push(result);
      })
    })

    const localData = localStorage.getItem('config')
    if(localData){
      this.showComponent =true;
    } else{
      this.showComponent =false;
    }

    //Added this logic on the backend to create a new transaction id if not present
    // if (this.currentItem) {
    //   this.transIDExist = this.fireService.isTransactionIdExists(this.transId, this.userName).then((value) => {
    //     if (value) {
    //       this.addUser(this.userName, this.userId);
    //     }
    //     else {
    //       this.router.navigate(['pages/miscellaneous/404']);
    //     }
    //   })
    // }

  }


  onSelected(value): void {
		this.selectedSiteId = JSON.parse(value);
    this.site_details = {
      "site_id": this.selectedSiteId["site_id"],
      "site_name": this.selectedSiteId["site_name"],
      "server_ip": this.selectedSiteId["server_ip"],
    }
    if(value==='Please Select a Site'){
      this.isDisabled=true;
      this.nextBtnEnable=true;
    }else{
      this.isDisabled=false;
    }
    const cameraList = this.fireService.getCameras(this.selectedSiteId["site_id"]);
    cameraList.then((val)=>{
      val.forEach(element => {
        if(!element["stream"]){
          this.cameraNames.push(element);
        }
      });
    })
    
	}

  onCameraSelected(camVal:string): void {
		this.selectedCamera = JSON.parse(camVal);
    this.nextBtnEnable=false;
    this.site_details.camername = this.selectedCamera["cameraName"];
    this.site_details.port = this.selectedCamera["port"];
    this.site_details.serial_no = this.selectedCamera["serialNo"];
    this.site_details.cameraId = this.selectedCamera["cameraId"];
    localStorage.clear();
    localStorage.setItem('config', JSON.stringify(this.site_details));

	}

  addUser(userName, userId){
// Adds the user into firestore needs to be removed later
    const querySnapshot = getDocs(collection(this.db, 'user_list'));
    querySnapshot.then((a) => {
      this.userList=[];
      a.forEach(async (val) => {
        this.userList.push(await val.data()['username'])
      })
    });

    setTimeout(()=>{
      if (this.userList.includes(userName)) {
        console.log("username already exists.....")
      } else {
        setDoc(doc(this.db, "user_list", userId), {
          username: userName
        });
      }
    }, 2000)
  }   
  }
