<div class="header-container">
  <div class="logo-container">
    <a class="logo" href="/">
    <img src="../../../../assets/images/globus-medical.png"  width="80" height="70" style="margin-left: 9px;">
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- <nb-user size="medium"
               [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               showTitle=false
               color="#4479e7">
      </nb-user> -->
      <button class="exit" (click)="exit()" width="140px"> 
          <span class='fa fa-power-off' style="font-size: medium;"></span>        
          <span style="font-size: medium;"><b> Exit</b></span>     
      </button>

    </nb-action>
  </nb-actions>
</div>
