<!-- <div class="align-items-center">
<app-toaster></app-toaster>
</div> -->
<section class="container justify-content-center">
  <!-- <article class="row"> -->
    <!-- <div class="col-md-2 align-items-center"></div> -->
    <!-- <app-recording-symbol class="col-md-1 d-flex align-items-center"></app-recording-symbol>
    <div class="col-md-6 d-flex align-items-center justify-content-center"><h1 class="ml-5">Scan Image</h1></div>
    <div class="col-md-3 d-flex align-items-center justify-content-center"><a name="" id="" class="btn btn-danger" href="/" role="button">End Transaction</a></div> -->
    <!-- <div class="col-md-1 d-flex align-items-center"></div> -->
    <!-- <div class="align-items-center justify-content-center font-weight-bold"><h1>Scan Image: {{ transId }}</h1></div> -->
    <!-- <div class="col-md-3 d-flex align-items-center justify-content-center" (click)="endTransaction()"><a class="btn btn-danger" role="button">End Transaction</a></div> -->
    <!-- <div class="col-md-3 d-flex align-items-center justify-content-center">  -->
        <!-- <button class="btn btn-danger" style="width: fit-content; " (click)="endTransaction()"> 
          <span style="font-size: medium;">End Transaction</span>     
      </button> -->
    <!-- </div> -->
    
  <!-- </article> -->
  <article class="live-capture justify-content-around mb-3">
    <div>
      <!-- <div class="align-items-center justify-content-center font-weight-bold text-center"><h1>Scan Image: {{ transId }}</h1></div> -->
    <div class="camera" >
      <!-- <h2>Live Preview</h2> -->
      <img [src]="liv_preview_url" alt="">
      <div class="overlay">
        <app-recording-symbol class="align-items-bottom" ></app-recording-symbol>
      </div>
    </div>
    <div class="text-center mt-2">
      <!-- <button class="btn btn-success" (click)="startAutoCapture($event)">{{buttonText}}</button> -->
      <div class="d-flex justify-content-around" id="settings-container">
        <button class="btn btn-default active mb-2 mt-2" (click)="ToggleSettings(1)">1. Linear</button>
        <button class="btn btn-default mb-2 mt-2" (click)="ToggleSettings(2)">2. Circular</button>
        <button class="btn btn-default mb-2 mt-2" (click)="ToggleSettings(3)">3. Circumferential</button>
      </div>
          <button class="btn btn-success mb-2 mt-2 btn-block" (click)="CaptureImage($event)">Manual Capture</button>      
    </div>
  </div>  
    <!-- <div class="d-flex justify-content-center align-items-center flex-column"> -->
      <!-- <div>
        <div class="font-weight-bold text-center"><span style="font-size: 20px;">Scan Image: {{ transId }}</span></div>
      </div> -->
      <div>
        <div>
          <h4 style="font-size: 20px; font-weight: bolder;"  class="text-center"><u>SCAN IMAGE</u></h4>
          <h4 style="font-size: 20px; font-weight: 500;"  class="mb-5 text-center">{{ transId }}</h4>
        </div>
          <div class="d-flex flex-column">
          <!-- <select (change)="onSelect($event)" class="yellowbg" id="dropdown" >
            <-- <option value="" disabled selected hidden>Choose one</option> ->
            <option value="setting1" style="background-color: white;" selected >Linear</option>
            <option value="setting2" style="background-color: white;">Circular</option>
            <option value="setting3" style="background-color: white;" >Circumferential</option>
          </select> -->
          <!-- <button class="btn btn-success mb-2 mt-2 btn-block" (click)="CaptureImage($event)">Manual Capture</button> -->
          <button class="btn btn-success mt-2" (click)="startAutoCapture($event)">{{buttonText}}</button>
          <button class="btn btn-danger mt-2 btn-block" (click)="endTransaction()"> 
            <span style="font-size: medium;">End Transaction</span>     
          </button>
        </div>
  </div>
</article>


<!-- <div class="d-flex justify-content-center align-items-center flex-column">
<button class="btn btn-success mb-2 mt-2" (click)="CaptureImage($event)">Capture</button>
<button class="btn btn-success">Start Auto Capture</button>
</div> -->


</section>
