<nb-layout>
  <nb-layout-column>

<div class="container-fluid pb-3" style="background-color: #edf1f7; padding: 0px;">
    <app-navbar></app-navbar>
    <div class="m-3" style="background-color: white;">
        <div *ngIf="showComponent">
            <app-scan-image-single *ngIf="currentItem"></app-scan-image-single>
            <app-transaction-table *ngIf="currentItem" [item]="currentItem"></app-transaction-table>
        </div>

        <div *ngIf = "!showComponent">
            <div class="container">
                <div class="row">
                  <div class="col m-5">
                    <div class="form-group">
                      <label for="dropdown1"><b>Site</b></label>
                      <select #sites class="form-control" id="dropdown1" (change)="onSelected(sites.value)">
                        <ng-container>
                            <option value="" disabled selected hidden>Please Select a Site</option>
                            <option *ngFor="let i of siteNames" [value]="i |json">{{i.site_name | titlecase }}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col m-5">
                    <div class="form-group">
                      <label for="dropdown2"><b>Camera</b></label>
                      <select #cameras class="form-control" id="dropdown2" (change)="onCameraSelected(cameras.value)" [disabled]="isDisabled">
                        <ng-container>
                            <option value="" disabled selected hidden>Select a Camera</option>
                            <option  *ngFor="let x of cameraNames" [value]="x |json">{{x.cameraName}}</option>
                        </ng-container>                      
                      </select>
                    </div>
                  </div>



                  <div class="col-2 justify-content-center d-flex align-items-center m-5">
                      <button class="btn btn-primary btn-lg" (click)="showComponent = !showComponent" [disabled]="nextBtnEnable">Next</button>
                  </div>

                </div>

              </div>
        </div>
    </div>
</div>

</nb-layout-column>
</nb-layout>