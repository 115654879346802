import { Component, DoCheck  } from '@angular/core';
import { Location } from '@angular/common';
import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent implements DoCheck  {

  menu = MENU_ITEMS;
  constructor(private location: Location){
  }


  ngDoCheck(): void {
    // Fix for https://github.com/akveo/nebular/issues/1242
    if (this.location.path() === '' && !this.menu[0].selected) {
      // Select home menu item when navigating to the site root
      var i: any;
      for (i in this.menu) {
        this.menu[i].selected = (i == 0);
      }
    }
  }
}
