import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
  export class QueryParamGuard implements CanActivate {
  
    constructor(private router: Router) { }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const queryParams = route.queryParams;
      if (!queryParams || Object.keys(queryParams).length === 0) {
        this.router.navigate(['pages/miscellaneous/404']);
        return false;
      }
      return true;
    }
  }
  