import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import { OAuthProvider } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) { }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    return user !== null ? true : false;
  }



  // Sign in with Microsoft
  async Login() {
    this.router.navigate(['pages/tables/tree-grid']);
    // const provider = new OAuthProvider('microsoft.com');
    // provider.setCustomParameters({
    //   prompt: "consent",
    //   tenant : "50a947dd-36f3-4e7e-aacd-5353bdf81a12"
    // });
    // return this.AuthLogin(provider).then((res: any) => {
    //   this.afAuth.authState.subscribe((user) => {
    //     if (user) {
    //       this.userData = user;
    //       localStorage.setItem('user', JSON.stringify(this.userData));
    //       JSON.parse(localStorage.getItem('user')!);
    //       this.router.navigate(['pages/iot-dashboard']);
    //     } else {
    //       localStorage.setItem('user', 'null');
    //       JSON.parse(localStorage.getItem('user')!);
    //       localStorage.removeItem('user');
    //     }
    //   });
      
    // });
  }

  // Auth logic to run auth providers
  async AuthLogin(provider: any) {
    return await this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result)
        // this.router.navigate(['pages/iot-dashboard']);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Sign out
  async SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }
}
