<section class="container-fluid">
    <table class="table mt-3 justify-content-center table-striped" id="myTable">
      <thead>
        <tr>
          <th class="text-center align-middle">Sl No.</th>
          <th class="text-center align-middle">Image</th>
          <th class="align-middle">Part Number</th>
          <th class="align-middle">Lot Number</th>
          <th class="text-center align-middle">Remark</th>
          <th class="text-center align-middle">Verification</th>
          <th class="text-center align-middle">Action</th>
          <th class="text-center align-middle">Delete</th>
          <th class="text-center align-middle">LabelPrint</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let i of result; let x = index">
          <tr [ngStyle]="{'background-color':i.sr_no %2 === 0 ? 'rgb(230, 230, 230)' : 'white' }">
            <td class="text-center align-middle">{{i.sr_no}}</td>
            <!-- <td class="text-center align-middle"><img src="{{i.img_url[0]}}" alt="Image" class="align-middle"></td> -->
            <td class="text-center align-middle"><a data-toggle="modal" data-target="#imagePreview" (click)="imageModalOpen(i)"><img src="{{i.img_url[0]}}" alt="Image" class="align-middle"></a></td>
            <td class="align-middle">{{i.part_no}}</td>
            <td class="align-middle">{{i.lot_no}}</td>
            <td class="text-center align-middle" *ngIf="i.remark"><div class="remark good mx-auto"></div></td>
            <td class="text-center align-middle" *ngIf="i.remark == false"><div class="remark bad mx-auto"></div></td>
            <td class="text-center align-middle" *ngIf="!i.remark  && i.remark !== false"><div class="remark bad mx-auto"></div></td>
            <td class="text-center align-middle" *ngIf="i.verification"><div class="verification good mx-auto"></div></td>
            <td class="text-center align-middle" *ngIf="i.verification == false"><div class="verification bad mx-auto"></div></td>
            <td class="text-center align-middle" *ngIf="!i.verification && i.verification !== false"><div class="verification bad mx-auto"></div></td>
            <td class="text-center align-middle"><a name="view" id="view" class="btn btn-success" data-toggle="modal" data-target="#preview" (click)="modalOpen(i)">View</a></td>
            <!-- <td class="text-center align-middle"><i class="icon ion-trash-b"(click)="delete(i)"></i><input type="hidden"></td> -->
            <td class="text-center align-middle"><i class="icon ion-trash-b"(click)="modalDelete(i)" data-toggle="modal" data-target="#delete_modal"></i><input type="hidden"></td>
            <td class="text-center align-middle"><a name="Print" id="Print" style="background-color: #183e7a !important;" class="btn btn-info btn-rounded" (click)="fnLPAPrint(i)" >Print</a></td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <!-- Modal -->
<div class="modal fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body d-flex flex-column align-items-center">
                <div style="width:70%" [class.fullscreen]= "fullscreen" class="image-viewer-container">
                  <div class="image-viewer">
                    <!-- <img src="../../assets/images/transaction_images/gold-cap.jpg" alt="Image" class="modal-image"> -->
                    <!-- <ngx-image-viewer  [src]="img_url"  [(index)]="imageIndex"  [config]="config">
                    </ngx-image-viewer> -->
                    <ngx-image-viewer  [src]="img_url"  [(index)]="imageIndex"  #imageViewer>
                    </ngx-image-viewer>
                  </div>
                </div>
                <div style="width:70%">
                <form [formGroup]="formdata" class="container-fluid">
                  <div class="row mt-4">
                    <div class="form-group col-md-6">
                        <label for="partNumber">Part Number</label>
                        <input type="text" class="form-control" formControlName="partNumber" id="partNumber" [(ngModel)]="partNo">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="lotNumber">Lot Number</label>
                        <input type="text" class="form-control" formControlName="lotNumber" id="lotNumber" (ngModelChange)="getSuggestions()">

                        <div class="col-12 mt-2 d-flex flex-wrap justify-content-around mb-3" id="pill-container" *ngIf="suggestions.length>0">
                          <span  *ngFor="let i of suggestions" (click)="setLotNoVal(i)">                    
                            <div class="badge badge-pill badge-primary chip mt-2" data-mdb-ripple-color="dark" style="padding: 4px 9px;" *ngIf="i">
                              {{i.Lot_Number}} <i class="close fas fa-times" (click)="removePill(i)" style="font-size: small; margin-left: 5px;"></i>
                            </div>
                          </span>
                        </div> 
                      </div>
                  </div>
                    <a class="btn btn-success" href="#" data-dismiss="modal" role="button" (click)="saveModal(formdata.value)">Save</a>
                    <!-- <button type="button" class="close badge badge-pill" data-dismiss="modal">Close</button> -->
                    <a class="btn btn-danger ml-3" href="#" data-dismiss="modal" role="button" >Close</a>

                </form>
              </div>
            </div>
        </div>
    </div>
    </div>


<!-- Modal -->
<div class="modal fade" id="delete_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog deleteModal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirm Delete <i class="ion-trash-b"></i></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you really want to delete the record? This process cannot be undone.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancelDelete()">No</button>
        <button type="button" class="btn btn-danger" (click)="delete(deleteRecord)" data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div> 


<div class="modal fade" id="imagePreview" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
          <div class="modal-body d-flex flex-column align-items-center">
            <div class="d-flex align-self-end">
              <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
            </div>
              <div style="width:55vw" [class.fullscreen]= "fullscreenImage" class="image-viewer-container">
                <div class="image-viewer">
                  <ngx-image-viewer  [src]="img_url_only"  [(index)]="imageIndexOnly"  #imageViewerOnly>
                  </ngx-image-viewer>
                </div>
              </div>
              <div style="width:70%">
            </div>
          </div>
      </div>
  </div>
  </div>

</section>
