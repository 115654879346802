import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { AuthGuard } from './guard/auth.guard';
import { SinglePreviewComponent } from './single-preview/single-preview.component';
import { QueryParamGuard } from './query-param.guard';

export const routes: Routes = [
  // { path: '', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
      canActivateChild:[AuthGuard]
  },
  {path: '',component:SinglePreviewComponent,canActivate:[QueryParamGuard]
},
  // {path: 'live-preview', component:SplitScreenComponent},

  // {path: '',component:SplitScreenComponent},
  // {path: 'preview', component:SinglePreviewComponent},
  // { path: '', redirectTo: 'pages', pathMatch: 'full' },
  // { path: '**', redirectTo: 'pages' },
  // { path: 'login', component: LoginComponent, pathMatch: 'full' },
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
 
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: 'pages', component: PagesComponent, canActivate: [AuthGuard] },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(){
  }
}
