import { Component, OnInit } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { environment } from '../../environments/firebaseConfiguration';
import { doc, deleteDoc, updateDoc, collection, where,query, orderBy  } from "firebase/firestore";

@Component({
  selector: 'app-recording-symbol',
  templateUrl: './recording-symbol.component.html',
  styleUrls: ['./recording-symbol.component.css']
})
export class RecordingSymbolComponent implements OnInit {
  record_color:String = "red";
  // Initialize Firebase
  app = initializeApp(environment.firebaseConfig);
  // Initialize Cloud Firestore and get a reference to the service
  db = getFirestore(this.app);
  siteId ='';
  cameraId = '';
  indicator = false;

  ngOnInit() { 
    const localData = JSON.parse(localStorage.getItem('config'));
    this.siteId = localData['site_id'];
    this.cameraId = localData['cameraId'];
    const docRef = doc(this.db, `${environment.siteCollection}/${this.siteId}/cameras/`,  this.cameraId);
    
    const q = docRef;
    onSnapshot(q, (doc) => {
        this.indicator =  doc.data()['indicator']
        this.indicator ? this.record_color="green": this.record_color="red";
});
}
}
