import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbSpinnerService } from '@nebular/theme';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  // router:String;

  constructor(private router: Router,private spinnerService: NbSpinnerService){
    // this.router = _router.url;
    // console.log(this.router)
    this.spinnerService.load();

  }

  hasRoute(route: string) {
    // console.log(this.router.url)
    return this.router.url.includes(route);
  }

}
