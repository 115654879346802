import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getFirestore, updateDoc, } from "firebase/firestore";
import { environment } from '../environments/firebaseConfiguration';
import { getDoc, setDoc, doc, getDocs, collection,where,query  } from "firebase/firestore";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { param } from 'jquery';
import { __param } from 'tslib';


@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    serverIp ='';
    portNo = '';
    private bigQueryUrl = environment.bigqueryUrl;
    private tokenUrl = environment.tokenUrl;
    private gatewayKey = environment.gatewayKey;
    constructor(private http: HttpClient) { }


    initApp = initializeApp(environment.firebaseConfig);
    db = getFirestore(this.initApp);
    collectionName = environment.collectionName;
    userCollection = environment.userCollection;
    transactionRef = collection(this.db, this.collectionName);
    transIdSet = new Set();
    prodMap = new Map();
    transactionLit = [];
    filterScans = [];
    count = 0

    // async getAllTransaction() {
    //     const querySnapshot = await getDocs(collection(this.db, this.collectionName));
    //     querySnapshot.forEach((doc) => {
    //         this.transactionLit.push(doc.data());
    //     })
    //     return this.transactionLit;
    // }
    getLocalStorageData(){
        const localData = JSON.parse(localStorage.getItem('config'));
        this.serverIp = localData['server_ip'];
        this.portNo = localData['port'];
    }

    CaptureImage(tr_id: any){
        // const params = new URLSearchParams();
        const params ={'transaction_id': tr_id}
        // params.set('transaction_id', "test1");
        this.getLocalStorageData();
        const url = 'https://'+this.serverIp+'/'+this.portNo+'/captureImage'
        // this.http.post<any>(url, params).subscribe(data => {
        //     console.log("Capture Response : ",data);
        // }) 
        return this.http.post<any>(url, params);
    }

    StartAutoCapture(tr_id: any, triggerVal){
        // const params = new URLSearchParams();
        const params ={'transaction_id': tr_id, 'triggerVal': triggerVal}
        // params.set('transaction_id', "test1");
        this.getLocalStorageData();
        const url = 'https://'+this.serverIp+'/'+this.portNo+'/autocaptureToggle'
        this.http.post<any>(url, params).subscribe(data => {
        console.log("Auto Capture Response : ",data);
    }) 
    }


    ValidateData(lot_no, part_no, transaction_id, document_id){
        // const params = new URLSearchParams();
        const params ={'lot_no': lot_no, 'part_no':part_no, 'tr_id' : transaction_id, 'doc_id': document_id}
        console.log(params,'______params_______')
        this.getLocalStorageData();
        const validateUrl = 'https://'+this.serverIp+'/'+this.portNo+'/validateData'
        // params.set('transaction_id', "test1");
        this.http.post<any>(validateUrl, params).subscribe(data => {
        console.log("Saved Response : ",data);
    }) 
    }

     async getAllUsers() {
        let userList =[];
        const querySnapshot = await getDocs(collection(this.db, this.userCollection));
        querySnapshot.forEach((users) => {
            userList.push(users.data());
        })
        return userList;
    }



    //retrive data by transaction id
    async getDataByTransId(transId: string) {
        const pSnap = await getDocs(collection(this.db, `${environment.collectionName}/${transId}/products`));
        const parts: any = [];
        let index = 0
        pSnap.forEach((doc1) => {
            const oneTrData = doc1.data()
            index = index + 1
            const obj = {
                slno: index,
                lotNo: `${oneTrData['lot_no'] ? oneTrData['lot_no'] : ''}`,
                partNo: `${oneTrData['part_no'] ? oneTrData['part_no'] : ''}`,
                remark: oneTrData['remark'],
                verification: oneTrData['master_data'],
                imgurl: oneTrData['img_url'],
            }
            parts.push(obj)

        })
        return parts;
    };

    async isTransactionIdExists(transId, username){
        //Checks the transaction ID exists in firestore or not
        const docRef = doc(this.db, environment.collectionName, transId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return true;
        } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
        // Todo temp code will be removed later
        setDoc(doc(this.db, environment.collectionName, transId), {
            transaction_id: transId,
            created_on: new Date(),
            username: username,
            open:false
          });
        return true;
        }


    }

    switchFlag(transId, username){
        //Sets the Flag to True
        console.log('inside switch.....')
        const docSnap = updateDoc(doc(this.db, environment.collectionName, transId), {
            open:true
          });
          console.log('inside switch.....', docSnap)
        } 

    async getSite() {
        const pSnap = await getDocs(collection(this.db, 'sites'));
        const sites: any = [];
        pSnap.forEach((doc) => {
            const onesite = doc.data()
            const obj = {
                site_id: doc.id,
                server_ip:onesite['server_ip'],
                site_name:onesite['site_name']
            }
            sites.push(obj)
        })
        return sites;
    };    
    
    async getCameras(siteId) {
        const pCam = await getDocs(collection(this.db, `sites/${siteId}/cameras`));
        
        const cameras: any = [];
        pCam.forEach((doc) => {
            const onesite = doc.data()
            const obj = {
                cameraId:doc.id,
                cameraName:onesite['name'],
                port:onesite['port'],
                serialNo:onesite['serial_no'],
                stream:onesite['stream']
            }
            cameras.push(obj)
        })
        console.log(cameras,'____Camera______')
        return cameras;
    };    

    settingsURL(settingsVal){
        const params = {'settingid': settingsVal};
        this.getLocalStorageData();
        const validateUrl = 'https://'+this.serverIp+'/'+this.portNo+'/setProperties';
        this.http.post<any>(validateUrl, params).subscribe(data => {
        console.log("Saved Response : ",data);
    }) 
    }


    getSimilarLots(lotNo: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('x-api-key', this.gatewayKey);
        let params = new URLSearchParams();
        params.set('lot_no', lotNo);
        // const params = {"lot_no":lotNo}
        return this.http.post<any>(this.bigQueryUrl, params.toString(),{headers:headers});
      } 


    getToken(token: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('x-api-key', this.gatewayKey);
        let params = new URLSearchParams();
        params.set('token', token);
        params.set('action', 'extract');
        return this.http.post<any>(this.tokenUrl, params.toString(),{headers:headers});
      } 
}