import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import { ActivatedRoute } from '@angular/router';
import { elementAt } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';


@Component({
  selector: 'app-scan-image-single',
  templateUrl: './scan-image-single.component.html',
  styleUrls: ['./scan-image-single.component.css']
})
export class ScanImageSingleComponent implements OnInit{
  paramId = '';
  paramUser = '';
  transId = ''
  liv_preview_url = '';
  serverIp = '';
  portNo = '';
  selectedOption: string;
  camSettings: string;
  buttonText = "Start Auto Capture"
  tokenData : any;
  selectedColor :any =1;
  streamValue :any;
  site_id: string;
  cameraId: string;
  captureCount = 0; // Track the number of captures
  
  constructor(private fireService: FirebaseService, private route: ActivatedRoute, private toastrService: NbToastrService ) {
  }

  ngOnInit(): void {

    let token = this.route.snapshot.queryParamMap.get('token');
    
    this.fireService.getToken(token).subscribe((val)=>{
      this.tokenData = val;
      console.log("TokenData:",this.tokenData);
      this.paramId = this.tokenData['decoded_token'].user_id;
      this.paramUser = this.tokenData['decoded_token'].username;
      this.transId = this.tokenData['decoded_token'].transaction_id;
    });
   
    const localData = JSON.parse(localStorage.getItem('config'));
    this.serverIp = localData['server_ip'];
    this.portNo = localData['port'];
    this.liv_preview_url = 'https://'+this.serverIp+'/'+this.portNo+'/video_feed';
    this.site_id = localData['site_id']
    this.cameraId = localData['cameraId'];
    // this.fireService.switchFlag(this.transId, this.paramUser);

    this.streamValue = this.fireService.getCameras(this.site_id);
    this.streamValue.then((val)=>{
      val.forEach(element => {
        if(element["cameraId"]===this.cameraId){
          if(element["stream"]){
            localStorage.removeItem("config");
            location.reload();
          }
        }
      });
    })
  }

  CaptureImage(event: Event){
    this.fireService.CaptureImage(this.transId).subscribe(data => {
          console.log("Capture Response : ",data);
          if(data["status"] == "success"){
            this.ToastTimer();
          }
      }) 
    // this.showToast(EventTypes.Success);

   }

   ToastTimer(){
    if (this.selectedColor === 3) { 
      this.captureCount++; // Increment the capture count

      if (this.captureCount === 1) {
        this.showToast('success', 'Capture 1 is complete', 'First capture is complete.');
      } else if (this.captureCount === 2) {
        this.showToast('success', 'Capture 2 is complete', 'Second capture is complete.');
        this.captureCount = 0; // Reset the capture count
      }
    }
   }

   private showToast(status: string, title: string, message: string) {
      this.toastrService.show(message, title, { status });
    }

   startAutoCapture(event: Event){
    this.buttonText = this.buttonText === 'Start Auto Capture' ? 'End Auto Capture' : 'Start Auto Capture';
    if(this.buttonText ==='Start Auto Capture'){
      this.fireService.StartAutoCapture(this.transId, 1);
    } else {
      this.fireService.StartAutoCapture(this.transId, 0);
    }
   }

   endTransaction() {
    this.fireService.switchFlag(this.transId, this.paramUser);
    setTimeout(()=>{
      // history.back();
      var win = window.open("about:blank", "_self");
      win.close();
    },1000)
   }

   ToggleSettings(index){
    let settings = document.querySelectorAll('#settings-container *')
    settings.forEach((element) => {
      element.classList.remove('active');
    });
    settings[index-1].classList.add('active');
    this.selectedColor =  index;
    this.onSelect(index);

   }
   onSelect(index) {
    switch(index){
      case 1:{
        this.selectedColor =  1;
        this.fireService.settingsURL('setting1');
        break;
      }
      case 2:{
        this.selectedColor =  2;
        this.fireService.settingsURL('setting2');
        break;
      }
      case 3:{
        this.selectedColor =  3;
        this.fireService.settingsURL('setting3');
        break;
      }
    }
  }
  //  onSelect(event: any) {
  //   switch(event.target.value){
  //     case 'setting1':{
  //       this.selectedColor =  1;
  //       break;
  //     }
  //     case 'setting2':{
  //       this.selectedColor =  2;
  //       break;
  //     }
  //     case 'setting3':{
  //       this.selectedColor =  3;
  //       break;
  //     }
  //   }
  //   this.fireService.settingsURL(event.target.value);
  // }
}

