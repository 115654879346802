import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Reports',
    icon: 'grid-outline',
    children: [
      {
        title: 'Search For Transaction',
        link: '/pages/tables/tree-grid',
      },
    ],
  },
];
